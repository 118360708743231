.bubble-container {
	margin: 10%;
}

.bubble-in {
	background-color : #151333;
	display: flex;
	border-radius: 50px;
	border: 5px solid #4f2dcf;

	min-width: 750px;
	min-height: 500px;

	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	position: absolute;
	z-index: 4;
}

.bubble-under {
	margin: 5%;

	border-radius: 50px;
	min-width: 750px;
	min-height: 500px;

	background-color: #4f2dcf;

	position: relative;
}

/* TIME */
.time-left {
	color: white;
	font-size: 20px;
	font-weight: 600;
	padding: 10px;
	min-height: 40px;
	min-width: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* TEXT WRITTER */
.Typewriter__cursor {
	display: none;
}

/* TEXT */
.typewriter {
	color: white;
	min-height: 230px;
	max-width: 510px;
	overflow: hidden;
}

.typewriter h2 {
	color: #74d19c;
}

.typewriter p {
	color: white;
	font-size: 22px;
	font-weight: 600;
}

.typewriter span {
	color: #f89141;
}


/* IMAGE */
.show-image {
	margin: -20px 0 20px 0;
	border: 3px solid #4f2dcf;
}

/* BUTTONS */
.bubble-buttons {
	margin-top: 15px;
}

/* SCORE */
.score-container {
	margin-top: 15px;
	color: white;
}

/* TABLET */
@media screen and (max-width: 1024px) and (min-width: 580px) {
  	.bubble-container {
		margin: 0;
		display: flex;
  	}

  	.bubble-in {
		min-width: 510px;
		min-height: 530px;
  	}

  	.bubble-under {
  		margin-top: 5%;
		min-width: 510px;
		min-height: 530px;
  	}

  	/* IMAGE */
	.show-image {
		margin: -50px 0 20px 0;
		border: 3px solid #4f2dcf;
	}

  	.typewriter {
		color: white;
		max-width: 470px;
		min-height: 205px;
	}

  	.typewriter p {
		font-size: 15px;
		font-weight: 600;
	}
}

/* PHONE */
@media screen and (max-width: 580px) {
  	.bubble-container {
		margin: 5% 0 0 0;
		display: flex;
  	}

  	.bubble-in {
		min-width: 320px;
		min-height: 428px;
  	}

  	.bubble-under {
  		margin-top: 5%;
		min-width: 320px;
		min-height: 428px;
  	}

  	.typewriter {
		min-height: 100px;
		max-width: 280px;
	}

  	.typewriter p {
		font-size: 12px;
		font-weight: 600;
	}
}