.right-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.pierreJean-container {
	height: 80%;
	margin-top: 20%;

	animation: rotate 600ms linear infinite;
	transform: rotate(5deg);
}

/* TABLET */
@media screen and (max-width: 1024px) and (min-width: 580px) {
  	.right-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 1024px;
	}

	.pierreJean-container {
		margin-top: -10%;
	}	
}


/* PHONE */
@media screen and (max-width: 580px) {
	.right-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 375px;
	}

	.pierreJean-container {
		max-width: 100%;
		max-height: 100%;
		margin-top: -12%;
	}	
}

@keyframes rotate {
	0% { transform: rotate(5deg) }
	50%{ transform: rotate(-5deg) }
    100%{ transform: rotate(5deg) }
}