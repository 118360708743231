@import './fonts.css';

html, body {
  background-color: #1f1c42;
  height: 100%;
}

.App {
  text-align: center;
  display: flex;
  font-family: Comfortaa;
}

.game-container {
  z-index: 2;
  display: inherit;
  width: 100%;
  display: flex;
  justify-content: center;
}

.background-image-container {
  background: no-repeat url('./img/cinema.jpg');
  background-size: 104%;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: .3;
  filter: blur(3px);
}


/* TABLET */
@media screen and (max-width: 1024px) and (min-width: 580px) {
  .background-image-container {
    background: none;
    height: 0;
    width: 0;
  }

  .game-container {
    margin-top: 5%;
    flex-direction: column;
    align-items: center;
  }
}

/* PHONE */
@media screen and (max-width: 580px) {
  .App {
    width: 100%;
    height: 100%;
  }

  .background-image-container {
    background: none;
    height: 0;
    width: 0;
  }

  .game-container {
    margin-top: 5%;
    width: fit-content;
    flex-direction: column;
    align-items: center;
  }
}

/* SIDEBAR CSS */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7238aa;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white;
}