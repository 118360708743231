@font-face {
    font-family: 'Cocogoose';
    src: url('./fonts/Cocogoose.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('./fonts/Comfortaa.ttf');
    font-weight: 300;
    font-style: normal;
}